import "./index.css";
import CardsBlog from "../../components/CardsBlog/CardsBlog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autor from "../../images/autor.png";
import imagemBlog from "../../images/image-blog.png";
import "./FontAwesome";

function TemplateBlog() {
  return (
    <main>
      <div class="container-post container">
        <div class="container-menu">
          <aside class="menu-lateral-post">
            <ul>
              <li>
                <a href="#1">Porque micro-frontends?</a>
              </li>
              <li>
                <a href="#2">Atualizações incrementais</a>
              </li>
              <li>
                <a href="#3">Facilitando a vida com códigos desacoplados</a>
              </li>
              <li>
                <a href="#4">Independência nos deploys</a>
              </li>
              <li>
                <a href="#5">Micro-frontends e equipes independentes</a>
              </li>
            </ul>
          </aside>
        </div>
        <div class="conteudo-post">
          <div class="banner-artigo">
            <span class="categoria-post">Novidades</span>
            <h1>
              Micro-frontends e suas arquiteturas:{" "}
              <span>Mais escalabilidade e independência</span>
            </h1>
          </div>
          <div class="autor-post">
            <img src={autor} alt="autor-post" />
            <p>
              por <span>DANILO MUNHE</span> 08 de outubro de 2024
            </p>
          </div>
          <div class="conteudo-blog">
            <h2 id="1">Por que usar micro-frontends?</h2>
            <p>
              Ao invés de ficar preso em detalhes técnicos ou na forma exata de
              implementar micro-frontends, vamos focar nas vantagens que eles
              trazem e nas qualidades que podemos aproveitar ao escolher essa
              arquitetura.
            </p>
            <h2 id="2">Atualizações incrementais</h2>
            <p>
              Para muitas empresas, esse é o começo da jornada com
              micro-frontends. Aquele monolito gigante que tá preso em uma stack
              antiga ou em código que foi escrito às pressas e chega um ponto em
              que reescrever tudo do zero parece a melhor solução. <br />
              <br /> Mas, em vez de cair na armadilha de querer refazer tudo, a
              gente prefere ir depreciando a aplicação antiga quebrando ela em
              partes menores enquanto continua entregando novas funcionalidades
              para os clientes - tudo isso sem ser arrastado pelo peso do
              monólito. <br />
              <br /> Isso geralmente leva à arquitetura de micro-frontends.
              Quando uma equipe consegue lançar uma nova funcionalidade com o
              mínimo de impacto no sistema legado as outras equipes começam a
              querer fazer isso também. O código legado ainda vai precisar ser
              mantido, e em alguns casos faz sentido continuar adicionando novas
              funcionalidades nele, mas agora a diferença é que temos mais
              opções. <br />
              <br /> No final das contas, ganhamos mais liberdade para tomar
              decisões caso a caso em diferentes partes do nosso produto, além
              de poder fazer atualizações incrementais na arquitetura, nas
              dependências e na experiência do usuário. Se rolar uma mudança
              grande no framework principal, cada micro front-end pode ser
              atualizado quando fizer sentido, ao invés de parar tudo para
              atualizar o sistema inteiro de uma vez só. <br />
              <br /> E se quisermos testar uma nova tecnologia conseguimos fazer
              isso de forma mais isolada e segura do que antes.
            </p>
            <h2 id="3">Facilitando a vida com código desacoplado</h2>
            <p>
              Cada micro front-end, por definição, vai ter uma base de código
              bem menor do que um front-end monolítico. E bases de código
              menores tendem a ser mais simples e fáceis de trabalhar. <br />
              <br /> Um dos maiores benefícios aqui é evitar aquela complexidade
              chata que surge quando componentes que não deviam saber nada um do
              outro acabam acoplados sem querer. Ao colocar limites mais claros
              entre as diferentes áreas da aplicação, a gente dificulta esse
              tipo de acoplamento acidental.
              <br />
              <br /> Mas, claro, uma decisão arquitetural em alto nível (tipo
              “vamos fazer micro-frontends”) não substitui as boas práticas
              conhecidas. Não é como se a gente parasse de pensar no código e se
              preocupar com a qualidade.
              <br />
              <br /> A ideia é criar um ambiente onde tomar decisões ruins fica
              difícil e fazer a coisa certa se torna o caminho mais fácil. Por
              exemplo, compartilhar modelos de domínio entre contextos
              diferentes fica mais complicado, então as pessoas desenvolvedoras
              acabam evitando isso.
              <br />
              <br />
              Além disso, micro-frontends forçam a gente a ser mais claro e
              intencional sobre como os dados e eventos fluem entre as partes da
              aplicação, algo que deveríamos estar fazendo de qualquer forma!
            </p>
            <h2 id="4">Independência nos deploys</h2>
            <p>
              Assim como nos microsserviços, a capacidade de fazer deploys
              independentes é fundamental para micro-frontends. Isso reduz o
              escopo de cada deploy, o que também diminui o risco envolvido.
              <br />
              <br />
              Não importa onde ou como o código fonte está, cada micro front-end
              deve ter sua própria pipeline de CI (entrega contínua) que
              constrói, testa e faz o deploy para produção.
              <br />
              <br />
              O ideal é que possamos publicar um micro front-end em produção sem
              se preocupar com o estado das outras bases de código ou pipelines.
              <br />
              <br />
              Não deveria importar se o monolito legado ainda segue um ciclo de
              lançamentos manual e trimestral, ou se a equipe ao lado liberou
              uma funcionalidade quebrada sem querer.
              <br />
              <br />
              Se o micro front-end está pronto ele deve poder ser publicado e
              essa decisão deve ser da equipe que é dona dele.
            </p>
            <img src={imagemBlog} alt="imagem noticia" />
            <h2 id="5">Micro-frontends e equipes independentes</h2>
            <p>
              Quando desacoplamos tanto o código quanto às publicações já damos
              um passo para criar equipes independentes que podem gerenciar uma
              parte do produto - desde a ideia inicial até a produção e
              manutenção.
              <br />
              <br /> Essas equipes têm responsabilidade por tudo que precisam
              para entregar valor aos clientes. Para isso funcionar as equipes
              precisam ser formadas em em verticais de funcionalidade e não em
              torno de capacidades técnicas.
              <br />
              <br />
              Uma maneira fácil de fazer isso é dividir o produto com base no
              que os usuários finais veem, assim cada micro front-end encapsula
              uma parte da aplicação e seja gerenciado de ponta a ponta por uma
              única equipe.
            </p>
          </div>
          <div class="container-redes-blog-post">
            <a href="#" className="icone-compartilhamento">
              <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />
            </a>
            <a href="#" className="icone-compartilhamento">
              <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />
            </a>
            <a href="#" className="icone-compartilhamento">
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            </a>
          </div>
        </div>
      </div>
      <div className="separador alternativo container"></div>
      <h2 className="title-post-relacionados container">Posts Relacionados</h2>
      <CardsBlog variant="templateBlog" />
      <div className="separador-footer container"></div>
    </main>
  );
}
export default TemplateBlog;
