import React from "react";
import mail from "../../images/mail.png";
function Newsletter() {
  return (
    <div className="container">
      <div className="separador"></div>
      <h2 className="title-newsletter">
        <span className="newsletter-part1">MANTENHA-SE INFORMADO</span> COM UMA{" "}
        <br /> <span className="newsletter-part2">NEWSLETTER EXCLUSIVA</span>
      </h2>
      <form action="#">
        <div className="input-newsletter">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="seuemail@dominio.com"
          />
          <button type="submit">
            <img src={mail} alt="mail" />
          </button>
        </div>
      </form>
    </div>
  );
}
export default Newsletter;
