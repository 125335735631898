import "./index.css";
import CardsProjetos from "../CardsProjetos/CardsProjetos";
function SectionProjetos() {
  return (
    <section className="projetos">
      <div className="textos-projetos">
        <h3>NEGOCIOS TRANSFORMADOS</h3>
        <h2>Projetos Desenvolvidos</h2>
        <p>
          Na Witbiz, acreditamos que a tecnologia, associada à pesquisa e
          expertise, trazem resultados concretos e de longo prazo.
        </p>
      </div>
      <CardsProjetos />
    </section>
  );
}

export default SectionProjetos;
