import React from "react";
function SectionFaleConosco() {
  return (
    <section className="fale-conosco" id="faleConosco">
      <div className="container">
        <div className="textos-fale-conosco">
          <h2>
            Gostou do que viu? <br />
            <span>Vamos crescer juntos</span>
          </h2>
          <p className="first">
            Conte-nos sua história e seus objetivos <br />
            para gente, queremos crescer junto com
            <br />
            você. Bora chegar lá?
          </p>
          <h2 className="desktop">
            Prefere falar <br />
            <span>pelo Whatsapp?</span>
          </h2>
          <p className="second desktop">
            Clique no botão abaixo e conte sua história <br />
            para gente.
          </p>
          <a className="button-whatsapp desktop" href="#">
            Whatsapp
          </a>
        </div>
        <div className="form-fale-conosco">
          <form action="#" method="post">
            <div className="dois-campos">
              <input
                type="text"
                name="nome"
                id="nome-contato"
                placeholder="Nome"
              />
              <input
                type="text"
                name="sobrenome"
                id="sobrenome-contato"
                placeholder="Sobrenome"
              />
            </div>
            <input
              type="text"
              name="empresa"
              id="empresa"
              placeholder="Nome da Empresa"
            />
            <input
              type="text"
              name="razao"
              id="razao"
              placeholder="Por que você procurou a Witbiz?"
            />
            <textarea
              name="mensagem"
              id="mensagem"
              placeholder="Conte um pouco sobre a sua ideia e seu objetivo..."
            ></textarea>
            <button type="submit">Enviar minha ideia</button>
          </form>
        </div>
        <div className="textos-fale-conosco">
          <h2 className="mobile">
            Prefere falar <br />
            <span>pelo Whatsapp?</span>
          </h2>
          <p className="second mobile">
            Clique no botão abaixo e conte sua história <br />
            para gente.
          </p>
          <a className="button-whatsapp mobile" href="#">
            Whatsapp
          </a>
        </div>
      </div>
    </section>
  );
}
export default SectionFaleConosco;
