import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import TemplateBlog from "./pages/TemplateBlog";
import Blog from "./pages/Blog";
function RoutesApp() {
  return (
    <BrowserRouter basename="/novo-witbiz">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/template-blog" element={<TemplateBlog />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
export default RoutesApp;
