import "./index.css";

function Footer() {
  return (
    <footer className="container">
      <div className="copyright">
        <span>Copyright 2024 | Todos os direitos reservados</span>
        <a href="#">Política de privacidade</a>
      </div>
      <div className="endereco">
        <span>
          R. Aureliano Guimarães, 150-172 - Vila Andrade, São Paulo - SP,
          05727-160
        </span>
      </div>
    </footer>
  );
}
export default Footer;
