import "./index.css";
import CardsBlog from "../CardsBlog/CardsBlog";
import Newsletter from "../Newsletter/Newsletter";
function SectionBlog() {
  return (
    <section className="blog" id="blog">
      <div className="container">
        <h3 className="description">BACKUP DE CONHECIMENTO</h3>
        <h2 className="title">Dicas & Novidades </h2>
        <p className="texto-quemsomos">
          Aqui você encontrará os assuntos mais relevantes sobre tecnologia,
          marketing e estratégias de crescimento utilizando as melhores práticas
          e tecnologias disponíveis.
        </p>
        <CardsBlog />
        <Newsletter />
      </div>
    </section>
  );
}
export default SectionBlog;
