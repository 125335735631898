import React from "react";
import { useState, useEffect } from "react";
import { register } from "swiper/element";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import vendasOnline from "../../images/vendas-online.png";
import crm from "../../images/crm.png";
import dev from "../../images/dev.png";
import marketing from "../../images/marketing.png";
import playCircle from "../../images/play_circle.png";

register();
const CardsServicos = () => {
  const [slidesPerView, setSliderPerview] = useState("auto");
  const [centerSlides, setcenterSlides] = useState(false);
  const cards = [
    {
      img: vendasOnline,
      title: "Vendas Online",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
    {
      img: crm,
      title: "CRM",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
    {
      img: marketing,
      title: "Growth Marketing",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
    {
      img: dev,
      title: "Desenvolvimento",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
    {
      img: vendasOnline,
      title: "Vendas Online",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
    {
      img: crm,
      title: "CRM",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
    {
      img: marketing,
      title: "Growth Marketing",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
    {
      img: dev,
      title: "Desenvolvimento",
      description:
        "Tenha tudo o que você precisa, de maneira integrada, para que você foque nas suas estratégias de venda.",
    },
  ];
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 720) {
        setSliderPerview("auto");
        setcenterSlides(true);
      } else {
        setSliderPerview(4);
        setcenterSlides(false);
      }
    }

    // Executa a função uma vez ao carregar o componente
    handleResize();

    // Adiciona o evento de resize
    window.addEventListener("resize", handleResize);

    // Adiciona suporte para mudanças no Device Mode do navegador
    const mediaQuery = window.matchMedia("(max-width: 720px)");
    function handleMediaChange(event) {
      if (event.matches) {
        setSliderPerview("auto");
        setcenterSlides(true);
      } else {
        setSliderPerview(4);
        setcenterSlides(false);
      }
    }
    mediaQuery.addEventListener("change", handleMediaChange);

    // Remove os listeners ao desmontar o componente
    return () => {
      window.removeEventListener("resize", handleResize);
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  return (
    <div className="container-cards-servicos swiper-container">
      <Swiper
        loop={true} // Navegação infinita
        spaceBetween={12}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={slidesPerView}
        centeredSlides={centerSlides}
        // navigation
        // pagination={{ clickable: true }}
        // centeredSlides={true}
      >
        {cards.map((card, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            <div className="card-servico">
              <img className="icone-servico" src={card.img} alt={card.title} />
              <h3>{card.title}</h3>
              <p>{card.description}</p>
              <div className="container-button-servicos">
                <a className="button-card" href="#">
                  Quero saber mais
                </a>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CardsServicos;
