import imagemDestaque from "../../images/artigoDestaque.png";
import "./index.css";
function BannerBlog() {
  const articles = [
    {
      title: "IA Conference Brasil 2024: confira tudo que rolou",
      description:
        "O interesse pelos temas que envolvem inteligência artificial não é à toa. De acordo com um estudo do Google,..",
    },
    {
      title: "IA Conference Brasil 2024: confira tudo que rolou",
      description:
        "O interesse pelos temas que envolvem inteligência artificial não é à toa. De acordo com um estudo do Google,..",
    },
    {
      title: "IA Conference Brasil 2024: confira tudo que rolou",
      description:
        "O interesse pelos temas que envolvem inteligência artificial não é à toa. De acordo com um estudo do Google,..",
    },
    {
      title: "IA Conference Brasil 2024: confira tudo que rolou",
      description:
        "O interesse pelos temas que envolvem inteligência artificial não é à toa. De acordo com um estudo do Google,..",
    },
    {
      title: "IA Conference Brasil 2024: confira tudo que rolou",
      description:
        "O interesse pelos temas que envolvem inteligência artificial não é à toa. De acordo com um estudo do Google,..",
    },
    {
      title: "IA Conference Brasil 2024: confira tudo que rolou",
      description:
        "O interesse pelos temas que envolvem inteligência artificial não é à toa. De acordo com um estudo do Google,..",
    },
  ];
  return (
    <div className="container-banner-blog container">
      <div className="container-articles post-relacionados container">
        {articles.slice(0, 3).map((article, index) => (
          <article key={index}>
            <h2>{article.title}</h2>
            <div className="separador"></div>
            <p>{article.description}</p>
          </article>
        ))}
      </div>
      <div className="artigo-destaque">
        <img src={imagemDestaque} />
        <h1>Copilot: como a IA pode transformar apresentações no PowerPoint</h1>
        <p>
          O PowerPoint é uma ótima e popular ferramenta para construção de
          apresentações, sejam elas corporativas, palestras, aulas, pitches
          comerciais, entre outras...
        </p>
      </div>
      <div className="container-articles post-relacionados container">
        {articles.slice(3, 6).map((article, index) => (
          <article key={index}>
            <h2>{article.title}</h2>
            <div className="separador"></div>
            <p>{article.description}</p>
          </article>
        ))}
      </div>
    </div>
  );
}
export default BannerBlog;
