import "./index.css";
import iconeVideo from "../../images/icone-video.png";
function SectionBanner() {
  return (
    <section className="pagina-inicial container">
      <h1>
        <span className="underline">Repense</span> o papel da tecnologia dentro
        da sua empresa
      </h1>
      <p>
        otimize processos burocráticos e foque no crescimento com o que a
        tecnologia oferece de melhor,
        <span className="underline"> resolução de problemas</span>
      </p>
      <a href="#videoPricipal">
        Assista ao vídeo
        <img src={iconeVideo} alt="icone-video" />
      </a>
    </section>
  );
}
export default SectionBanner;
