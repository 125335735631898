import React from "react";
import { Link } from "react-router-dom";

function CardsBlog({ variant = "default" }) {
  const articles = [
    {
      title: "Micro-frontends e suas arquiteturas: Mais escalabilidade ",
      description:
        "Desenvolver front-end do jeito certo é difícil. Escalar isso para que várias equipes possam trabalhar ao mesmo tempo em um produto grande e complexo...",
    },
    {
      title: "IA Conference Brasil 2024: confira tudo que rolou no evento",
      description:
        "O interesse pelos temas que envolvem inteligência artificial não é à toa. De acordo com um estudo do Google, 46% dos brasileiros utilizaram a IA de alguma forma nos...",
    },
    {
      title: "Copilot: como a IA pode transformar apresentações no PowerPoint",
      description:
        "O PowerPoint é uma ótima e popular ferramenta para construção de apresentações, sejam elas corporativas, palestras, aulas, pitches comerciais, entre outras..",
    },
    {
      title: "Copilot: como a IA pode transformar apresentações no PowerPoint",
      description:
        "O PowerPoint é uma ótima e popular ferramenta para construção de apresentações, sejam elas corporativas, palestras, aulas, pitches comerciais, entre outras..",
    },
  ];
  if (variant === "templateBlog") {
    return (
      <div className="container-articles post-relacionados container">
        {articles.slice(0, 3).map((article, index) => (
          <article key={index}>
            <h2>{article.title}</h2>
            <div className="separador"></div>
            <p>{article.description}</p>
            <Link className="button-leia-mais" to="/template-blog">
              Leia Mais
            </Link>
          </article>
        ))}
      </div>
    );
  } else if (variant === "blog") {
    return (
      <div className="container-articles post-relacionados container">
        {articles.map((article, index) => (
          <article key={index}>
            <h2>{article.title}</h2>
            <div className="separador"></div>
            <p>
              {article.description.length > 100
                ? `${article.description.slice(0, 100)}...`
                : article.description}
            </p>
          </article>
        ))}
      </div>
    );
  }
  return (
    <div className="container-articles">
      {articles.slice(0, 3).map((article, index) => (
        <article key={index}>
          <h2>{article.title}</h2>
          <div className="separador"></div>
          <p>{article.description}</p>
          <Link className="button-leia-mais" to="/template-blog">
            Leia Mais
          </Link>
        </article>
      ))}
    </div>
  );
}
export default CardsBlog;
