import "./index.css";
import buttonVideo from "../../images/button-play-video.png";
import videoHome from "../../videos/video-home.mp4";
import { useState, useRef } from "react";

function VideoPrincipal() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      setIsPlaying(true);

      video.style.objectFit = "cover";
      video.style.borderRadius = "4px";
      video.play();

      const duration = video.duration * 1000 + 2000;

      setTimeout(() => {
        setIsPlaying(false);
        video.pause();
        video.currentTime = 0;
        video.volume = 0;
      }, duration);
    }
  };

  return (
    <section className="video-servicos" id="videoPrincipal">
      <div className="video-servico container">
        {!isPlaying && (
          <>
            <h2 className="title-video-servico">
              Descubra o que a tecnologia tem de melhor a oferecer aos contextos
              da sua empresa
            </h2>
            <img
              className="button-play-video-servico"
              src={buttonVideo}
              alt="Play Video"
              onClick={playVideo}
            />
          </>
        )}

        <video
          id="videoServico"
          ref={videoRef}
          style={{
            width: isPlaying ? "100%" : "0%",
            height: isPlaying ? "100%" : "0%",
            transition: "all 0.3s ease-in-out",
          }}
          autoPlay
          loop
          muted
        >
          <source src={videoHome} type="video/mp4" />
          Seu navegador não suporta a tag de vídeo.
        </video>
      </div>
    </section>
  );
}

export default VideoPrincipal;
