import { Link } from "react-router-dom";
import "./index.css";
import logo from "../../images/Logo_Witbiz.png";
import menuLogo from "../../images/menu-burger.png";
import wpp from "../../images/logo-wpp.png";
import { useState } from "react";

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const baseUrl = window.location.pathname;

  function toggleMenu() {
    setMenuActive((prevState) => !prevState);
  }

  return (
    <header className="container">
      <img
        className="menu-burger mobile"
        src={menuLogo}
        alt="menu-burger"
        onClick={toggleMenu}
      />
      <img className="logo" src={logo} alt="logo" />
      <ul className={`menu ${menuActive ? "active" : ""}`}>
        <li>
          <Link className="active page" to={"/"}>
            Página Inicial
          </Link>
        </li>
        <li>
          <Link className="page" to={"/"}>
            Quem somos
          </Link>
        </li>
        <li>
          <Link className=" page" to={"/"}>
            Serviços
          </Link>
        </li>
        <li>
          <Link className=" page" to={"/blog"}>
            Blog
          </Link>
        </li>
        <li>
          <a href={`${baseUrl}#faleConosco`}>Contato</a>
        </li>
        <span class="close button-menu mobile" onClick={toggleMenu}>
          &times;
        </span>
      </ul>
      <a href={`${baseUrl}#faleConosco`}>
        <img className="wpp-logo mobile" src={wpp} alt="menu-burger" />
      </a>
      <div className="container-icone-button-menu">
        <a className="button-header" href={`${baseUrl}#faleConosco`}>
          Precisa de Ajuda?
        </a>
      </div>
    </header>
  );
}
export default Header;
