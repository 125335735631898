import "./index.css";
import CardsMobile from "../CardsServicos/CardsServicos";
function SectionServicos() {
  return (
    <section className="servicos" id="servicos">
      <div className="container">
        <h3 className="description">COMO ATUAMOS</h3>
        <h2 className="title">Tecnologia & Produtividade </h2>
        <p className="texto-quemsomos">
          Na Witbiz, acreditamos que a tecnologia, associada aos mais diversos
          campos de estudos, fazem com que ideias e negócios prosperem de
          maneira constante, saudável e orgânica.
        </p>
        <CardsMobile />
      </div>
    </section>
  );
}

export default SectionServicos;
