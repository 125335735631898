import SectionBanner from "../../components/sections/sectionBanner";
import SectionServicos from "../../components/sections/sectionServicos";
import VideoPrincipal from "../../components/sections/sectionVideoPrincipal";
import SectionProjetos from "../../components/sections/sectionProjetos";
import SectionBlog from "../../components/sections/sectionBlog";
import SectionFaleConosco from "../../components/sections/sectionFaleConosco";
function Home() {
  return (
    <main>
      <SectionBanner />
      <SectionServicos />
      <VideoPrincipal />
      <SectionProjetos />
      <SectionBlog />
      <SectionFaleConosco />
      <div class="separador-footer container"></div>
    </main>
  );
}
export default Home;
