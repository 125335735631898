import React from "react";
import { useState, useEffect } from "react";
import { register } from "swiper/element";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import intersena from "../../images/card-intersena.png";
import dropsa from "../../images/dropsa.png";
import biografica from "../../images/biografica.png";
import visibility from "../../images/visibility.png";

register();
const CardsProjetos = () => {
  const [slidesPerView, setSliderPerview] = useState("auto");
  const [slidesSpace, setSlidesSpace] = useState(120);

  const cards = [
    {
      img: intersena,
      title: "Intersena",
      description:
        "Entenda como transformamos a experiência do usuário e crescemos a conversão em mais de 30%.",
    },
    {
      img: biografica,
      title: "Biográfica",
      description:
        "Entenda como transformamos a experiência do usuário e crescemos a conversão em mais de 30%",
    },
    {
      img: dropsa,
      title: "Dropsa",
      description:
        "Saiba como fizemos as redes sociais ganharem um engajamento de mais de 40% em poucos meses de estratégia.",
    },
    {
      img: dropsa,
      title: "Dropsa",
      description:
        "Saiba como fizemos as redes sociais ganharem um engajamento de mais de 40% em poucos meses de estratégia.",
    },
    {
      img: dropsa,
      title: "Dropsa",
      description:
        "Saiba como fizemos as redes sociais ganharem um engajamento de mais de 40% em poucos meses de estratégia.",
    },
  ];
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 720) {
        setSliderPerview("auto");
        setSlidesSpace(30);
      } else {
        setSliderPerview(4);
        setSlidesSpace(120);
      }
    }

    // Executa a função uma vez ao carregar o componente
    handleResize();

    // Adiciona o evento de resize
    window.addEventListener("resize", handleResize);

    // Adiciona suporte para mudanças no Device Mode do navegador
    const mediaQuery = window.matchMedia("(max-width: 720px)");
    function handleMediaChange(event) {
      if (event.matches) {
        setSliderPerview("auto");
        setSlidesSpace(30);
      } else {
        setSliderPerview(4);
        setSlidesSpace(120);
      }
    }
    mediaQuery.addEventListener("change", handleMediaChange);

    // Remove os listeners ao desmontar o componente
    return () => {
      window.removeEventListener("resize", handleResize);
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  return (
    <div className="container-cards-projetos  swiper-container">
      <Swiper
        loop={true} // Navegação infinita
        spaceBetween={slidesSpace}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={slidesPerView}
        // navigation
        // pagination={{ clickable: true }}
        // centeredSlides={true}
      >
        {cards.map((card, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            <div className="card-projeto">
              <img src={card.img} alt={card.title} />
              <div className="informacoes-projeto">
                <h3>{card.title}</h3>
                <p>{card.description}</p>
                <a className="button-card-projeto" href="">
                  Quero ver mais
                </a>
              </div>
              <span className="visibilidade-projeto">
                <img src={visibility} alt="visibilidade" />
                98
              </span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CardsProjetos;
