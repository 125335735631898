import CardsBlog from "../../components/CardsBlog/CardsBlog";
import BannerBlog from "../../components/BannerBlog";
import BannerCRM from "../../images/bannerCrm.png";
function Blog() {
  return (
    <main id="blog">
      <BannerBlog />
      <div className="bannerCrm container">
        <img src={BannerCRM} />
        <div className="conteudo-bannerCrm">
          <h2>
            Conheça o CRM que está revolucionando o mercadocom uma ideia simples
            e personalização ilimitada
          </h2>
          <a href="#"> Quero saber mais</a>
        </div>
      </div>
      <CardsBlog variant="blog" />
      <div className="separador-footer container"></div>
    </main>
  );
}
export default Blog;
